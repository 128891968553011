var render = function render(){var _vm=this,_c=_vm._self._c;return _c('o-container',{ref:"container"},[(!_vm.showInformation)?_c('v-card',{attrs:{"elevation":"2","shaped":""}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"text-uppercase pl-4 mr-auto"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"d-flex justify-end ml-auto pr-0 pb-2"},[_c('o-button',{attrs:{"busy":_vm.busy,"color":"warning","icon":"mdi-filter","type":"label-icon","label":"Filter"},on:{"on-click":function($event){_vm.showFilter = true}}}),_c('o-button',{attrs:{"busy":_vm.busy,"color":"primary","icon":"mdi-sync","type":"label-icon","label":"Refresh"},on:{"on-click":function($event){return _vm.reset()}}}),(!_vm.busy)?_c('o-drawer',{attrs:{"auto-close":true,"actions":[
            {
              key: 'on-item-reset',
              label: 'RESET',
              icon: 'mdi-refresh',
              color: 'warning',
            },
            {
              key: 'on-item-search',
              label: 'SEARCH',
              icon: 'mdi-text-box-search-outline',
              color: 'success',
            },
          ],"button-icon":"mdi-filter-plus-outline","title":`FILTERS`,"use-button":false,"width":"50vw","visible":_vm.showFilter},on:{"on-item-search":function($event){_vm.showFilter = false;
            _vm.reset();},"on-item-reset":function($event){_vm.showFilter = false;
            _vm.reset(true);},"on-close":function($event){_vm.showFilter = false}}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('o-combo-box',{attrs:{"busy":_vm.busy,"label":"Account","items":_vm.accounts},model:{value:(_vm.parameters.account_id),callback:function ($$v) {_vm.$set(_vm.parameters, "account_id", $$v)},expression:"parameters.account_id"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('o-input',{attrs:{"busy":_vm.busy,"label":"Creative Asset ID"},model:{value:(_vm.parameters.creative_asset_id),callback:function ($$v) {_vm.$set(_vm.parameters, "creative_asset_id", $$v)},expression:"parameters.creative_asset_id"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('o-combo-box',{attrs:{"busy":_vm.busy,"label":"Source","items":_vm.sources},model:{value:(_vm.parameters.source_name),callback:function ($$v) {_vm.$set(_vm.parameters, "source_name", $$v)},expression:"parameters.source_name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('o-combo-box',{attrs:{"busy":_vm.busy,"label":"Status","items":_vm.fails},model:{value:(_vm.parameters.failed),callback:function ($$v) {_vm.$set(_vm.parameters, "failed", $$v)},expression:"parameters.failed"}})],1)],1)],1):_vm._e()],1)]),_c('v-divider'),(_vm.busy)?_c('v-skeleton-loader',{attrs:{"loading":_vm.busy,"type":"table","height":"","transition":"v-scroll-x-transition"}}):_vm._e(),_c('div',{style:(!_vm.busy && _vm.operation === '' ? '' : 'display:none')},[_c('o-table',{ref:"table",attrs:{"busy":_vm.busy,"type":"rest","has-paging":true,"method":"POST","height":_vm.getHeight(440),"fixed-header":true,"rest":{
          url: `/rest/intranet/execute?time=${new Date().getTime()}`,
          payload: {
            topic: '',
            name: 'check_asset_status',
            params: {
              a01: _vm.parameters.account_id,
              a02: _vm.parameters.failed,
              a03: _vm.parameters.creative_asset_id,
              a04: _vm.parameters.source_name,
              cnt: _vm.parameters.cnt,
              starts: _vm.parameters.starts,
            },
          },
        },"headers":[
          { text: '#', align: 'end', value: 'row_no', type: 'int' },
          {
            text: 'Type',
            align: 'start',
            value: 'source_name',
            type: 'text',
          },
          {
            text: 'Account',
            align: 'start',
            value: 'account_name',
            type: 'slot',
          },
          {
            text: 'Advertiser',
            align: 'start',
            value: 'advertiser_name',
            type: 'slot',
          },
          {
            text: 'Asset ID',
            align: 'end',
            value: 'creative_asset_id',
            type: 'text',
            width: 100,
          },
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'creative_asset_name',
            type: 'text',
          },
          {
            text: 'Meta Status',
            align: 'center',
            value: 'status',
            type: 'slot',
          },
          {
            text: 'Active',
            align: 'center',
            value: 'active',
            type: 'boolean',
          },
          {
            text: 'Transcoding Status',
            align: 'center',
            value: 'asset_status_name',
            type: 'slot',
          },
          {
            text: 'Encoded',
            align: 'end',
            value: 'encoded_assets',
            type: 'slot',
          },
          {
            text: 'Date Created',
            align: 'start',
            value: 'created_at',
            type: 'date-time',
          },
          {
            text: 'Created By',
            align: 'start',
            value: 'created_by_name',
            type: 'slot',
          },
          {
            text: 'Creatives',
            align: 'start',
            value: 'creative_count',
            type: 'slot',
          },
          {
            value: 'action',
            type: 'action',
            actions: [
              {
                key: 'on-item-view',
                label: 'View',
                icon: 'mdi-information-outline',
                color: 'success',
              },
            ],
          },
        ]},on:{"set-busy":_vm.setBusy,"on-item-view":_vm.onItemView},scopedSlots:_vm._u([{key:"slot",fn:function(row){return [(row.prop.key === 'creative_count')?_c('div',{staticClass:"text-center"},[(row.prop.item.creative_count > 0)?_c('o-button',{attrs:{"color":"blue lighten-2","icon":"mdi-motion-play-outline","type":"right-label-icon","label":`${row.prop.item.creative_count}`},on:{"on-click":function($event){return _vm.onItemCreative(row.prop.item)}}}):_vm._e()],1):_vm._e(),(row.prop.key === 'account_name')?_c('div',{staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(row.prop.item.account_name)+" ("+_vm._s(row.prop.item.account_id)+") ")]):_vm._e(),(row.prop.key === 'advertiser_name')?_c('div',{staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(row.prop.item.advertiser_name)+" ("+_vm._s(row.prop.item.advertiser_id)+") ")]):_vm._e(),(row.prop.key === 'created_by_name')?_c('div',{staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(row.prop.item.created_by_name)+" ("+_vm._s(row.prop.item.created_by)+") ")]):_vm._e(),(row.prop.key === 'encoded_assets')?_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(row.prop.item.encoded_assets.length)+" ")]):_vm._e(),(row.prop.key === 'asset_status_name')?_c('div',{staticClass:"text-center"},[_c('v-chip',{staticClass:"text-capitalize",attrs:{"color":row.prop.item.asset_status_name == 'Encoded'
                  ? 'success'
                  : [
                      'Queue',
                      'Transcoding HIDEF',
                      'HIDEF transcoded',
                      'Transcoding Last Formats',
                    ].includes(row.prop.item.asset_status_name)
                  ? 'orange darken-1'
                  : 'danger',"label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(row.prop.item.asset_status_name == "Encoded" ? "mdi-check" : [ "Queue", "Transcoding HIDEF", "HIDEF transcoded", "Transcoding Last Formats", ].includes(row.prop.item.asset_status_name) ? "mdi-wrench" : "mdi-close")+" ")]),_vm._v(" "+_vm._s(row.prop.item.asset_status_name == 'Unretriable'? 'Not Transcodable' : row.prop.item.asset_status_name)+" ("+_vm._s(row.prop.item.asset_status)+") ")],1)],1):_vm._e(),(row.prop.key === 'status')?_c('div',{staticClass:"text-center"},[_c('v-chip',{staticClass:"text-capitalize",attrs:{"color":row.prop.item.metadata.Status == 'Approved'
                  ? 'success'
                  : 'danger',"label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(row.prop.item.metadata.Status == "Approved" ? "mdi-check" : "mdi-close")+" ")]),_vm._v(" "+_vm._s(row.prop.item.metadata.Status)+" ")],1)],1):_vm._e()]}}],null,false,2629338494)})],1)],1):_vm._e(),(_vm.showInformation)?_c('v-card',{attrs:{"elevation":"2","shaped":""}},[_c('v-card-title',[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"6","xl":"6"}},[_c('div',{staticClass:"text-uppercase pl-4 mr-auto"},[_vm._v(" ASSET INFORMATION VIDEO ")])]),_c('v-col',{staticClass:"text-end ma-0 pa-0",attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"6","xl":"6"}},[(
              _vm.selectedAsset.video_status != 2 && _vm.selectedAsset.video_status != 11
            )?_c('o-button',{attrs:{"busy":_vm.busy,"color":"danger","icon":"mdi-alert-circle-outline","label":"Not Transcodable ","type":"label-icon"},on:{"on-click":function($event){return _vm.unretry()}}}):_vm._e(),_c('o-button',{attrs:{"busy":_vm.busy,"color":"success","icon":"mdi-sync","type":"label-icon","label":"Retry"},on:{"on-click":function($event){return _vm.retry()}}}),_c('o-button',{attrs:{"busy":_vm.busy,"color":"primary","icon":"mdi-keyboard-backspace","type":"label-icon","label":"Back"},on:{"on-click":function($event){_vm.showInformation = false;
              _vm.showFilter = false;}}})],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"ml-10 pb-5 mr-5"},[_c('v-row',{staticClass:"o-container-content"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v("SOURCE")])]),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"6","xl":"6"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v("Thumbnail:")]),(_vm.selectedAsset.creative_thumbnail_name !== '')?_c('img',{attrs:{"height":"100","src":_vm.selectedAsset.source_name == 'AUDIO'
                  ? _vm.selectedAsset.creative_thumbnail_name
                  : `${_vm.selectedAsset.media_host}/octilliontv/${_vm.selectedAsset.account_id}/${_vm.selectedAsset.advertiser_id}/thumb/${_vm.selectedAsset.creative_thumbnail_name}`}}):_vm._e()]),_c('div',{staticClass:"d-flex justify-space-between mt-6"},[_c('p',[_vm._v("Account Name:")]),_c('span',[_vm._v(_vm._s(_vm.selectedAsset.account_name)+" ("+_vm._s(_vm.selectedAsset.account_id)+")")])]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v("Type:")]),_c('span',[_vm._v(_vm._s(_vm.selectedAsset.source_name))])]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v("Asset ID:")]),_c('span',[_vm._v(_vm._s(_vm.selectedAsset.creative_asset_id))])]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v("Asset Name:")]),_c('span',[_vm._v(_vm._s(_vm.selectedAsset.creative_asset_name))])]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v("MIME Type:")]),_c('span',[_vm._v(_vm._s(_vm.selectedAsset.mime_type))])]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v("Encoding Profile:")]),_c('span',[_vm._v(_vm._s(_vm.selectedAsset.encoding_profile))])]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v("Date Created:")]),_c('span',[_vm._v(_vm._s(_vm.getDateTime(_vm.selectedAsset.created_at)))])]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v("Created By::")]),(_vm.selectedAsset.created_by > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedAsset.created_by_name)+" ("+_vm._s(_vm.selectedAsset.created_by)+") ")]):_vm._e()]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v("Active:")]),_c('v-chip',{attrs:{"color":_vm.selectedAsset.active ? 'success' : 'danger',"label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.selectedAsset.active ? "mdi-check" : "mdi-close")+" ")]),_vm._v(" "+_vm._s(_vm.selectedAsset.active ? "Yes" : "No")+" ")],1)],1),(
              _vm.selectedAsset.asset_status && _vm.selectedAsset.asset_status_name
            )?_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v("Transcoding Status:")]),_c('v-chip',{staticClass:"text-capitalize",attrs:{"color":_vm.selectedAsset.asset_status_name == 'Encoded'
                  ? 'success'
                  : 'danger',"label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.selectedAsset.asset_status_name == "Encoded" ? "mdi-check" : "mdi-close")+" ")]),_vm._v(" "+_vm._s(_vm.selectedAsset.asset_status_name)+" ("+_vm._s(_vm.selectedAsset.asset_status)+") ")],1)],1):_vm._e(),(_vm.selectedAsset.metadata && _vm.selectedAsset.metadata.Status)?_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v("Meta Status:")]),_c('v-chip',{staticClass:"text-capitalize",attrs:{"color":_vm.selectedAsset.metadata.Status == 'Approved'
                  ? 'success'
                  : 'danger',"label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.selectedAsset.metadata.Status == "Approved" ? "mdi-check" : "mdi-close")+" ")]),_vm._v(" "+_vm._s(_vm.selectedAsset.metadata.Status)+" ")],1)],1):_vm._e()]),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"6","xl":"6"}},[_c('o-table',{attrs:{"busy":true,"type":"array","has-paging":false,"no-count":true,"headers":[
              {
                text: 'Meta Data',
                align: 'start',
                sortable: false,
                value: 'label',
                type: 'slot',
              },
              {
                text: 'Status',
                align: 'start',
                sortable: false,
                value: 'status',
                type: 'slot',
              },
              {
                text: 'Allowed',
                align: 'start',
                sortable: false,
                value: 'allowed',
                type: 'text',
              },
              {
                text: 'Current',
                align: 'end',
                value: 'current',
                type: 'text',
              },
            ],"data":_vm.getMetaData(_vm.selectedAsset)},scopedSlots:_vm._u([{key:"slot",fn:function(row){return [(row.prop.key === 'label')?_c('div',{staticClass:"text-left text-capitalize"},[_vm._v(" "+_vm._s(row.prop.item.label.toString().replace(/_/g, " "))+" ")]):_vm._e(),(row.prop.key === 'status')?_c('div',{staticClass:"text-center"},[_c('v-chip',{staticClass:"text-capitalize",attrs:{"color":row.prop.item.status == 'Approve' ? 'success' : 'danger',"label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(row.prop.item.status == "Approve" ? "mdi-check" : "mdi-close")+" ")]),_vm._v(" "+_vm._s(row.prop.item.status)+" ")],1)],1):_vm._e()]}}],null,false,2810102447)})],1),(
            _vm.selectedAsset.preview_token && _vm.selectedAsset.preview_token != ''
          )?[_c('v-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v("PREVIEW")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('iframe',{staticStyle:{"max-height":"670px","height":"670px"},attrs:{"border":"0","width":"100%","src":`${_vm.getAPIUrl()}/creative_preview?preview=video&token=${
                  _vm.selectedAsset.preview_token
                }`}})])]:_vm._e(),(
            _vm.selectedAsset.encoded_assets &&
              _vm.selectedAsset.encoded_assets.length > 0
          )?[_c('v-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v("TRANSCODED")])]),_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.selectedAsset.encoded_assets),function(asset,i){return _c('v-row',{key:i},[_c('v-col',{staticClass:"mt-5 mb-0 pt-0 mb-0",attrs:{"cols":"12"}},[_c('h5',[_vm._v(_vm._s(asset.creative_asset_name))])]),_c('v-col',{staticClass:"mt-0 mb-0 pt-0 mb-0",attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"6","xl":"6"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v("Asset ID:")]),_c('span',[_vm._v(_vm._s(asset.creative_asset_id))])]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v("MIME Type:")]),_c('span',[_vm._v(_vm._s(asset.mime_type))])]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v("Date Created:")]),_c('span',[_vm._v(_vm._s(_vm.getDateTime(asset.created_at)))])]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v("Active:")]),_c('v-chip',{attrs:{"color":asset.active ? 'success' : 'danger',"label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(asset.active ? "mdi-check" : "mdi-close")+" ")]),_vm._v(" "+_vm._s(asset.active ? "Yes" : "No")+" ")],1)],1),(asset.asset_status && asset.asset_status_name)?_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v("Transcoding Status:")]),_c('v-chip',{staticClass:"text-capitalize",attrs:{"color":asset.asset_status_name == 'Encoded'
                        ? 'success'
                        : 'danger',"label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(asset.asset_status_name == "Encoded" ? "mdi-check" : "mdi-close")+" ")]),_vm._v(" "+_vm._s(asset.asset_status_name)+" ("+_vm._s(asset.asset_status)+") ")],1)],1):_vm._e(),(asset.metadata && asset.metadata.EncodedStatus)?_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v("Meta Status:")]),_c('v-chip',{staticClass:"text-capitalize",attrs:{"color":asset.metadata.EncodedStatus == 'Approved'
                        ? 'success'
                        : 'danger',"label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(asset.metadata.EncodedStatus == "Approved" ? "mdi-check" : "mdi-close")+" ")]),_vm._v(" "+_vm._s(asset.metadata.EncodedStatus)+" ")],1)],1):_vm._e()]),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('o-table',{attrs:{"busy":true,"type":"array","has-paging":false,"no-count":true,"headers":[
                    {
                      text: 'Meta Data',
                      align: 'start',
                      sortable: false,
                      value: 'label',
                      type: 'slot',
                    },
                    {
                      text: 'Status',
                      align: 'start',
                      sortable: false,
                      value: 'status',
                      type: 'slot',
                    },
                    {
                      text: 'Allowed',
                      align: 'start',
                      sortable: false,
                      value: 'allowed',
                      type: 'text',
                    },
                    {
                      text: 'Current',
                      align: 'end',
                      value: 'current',
                      type: 'text',
                    },
                  ],"data":_vm.getMetaData(asset)},scopedSlots:_vm._u([{key:"slot",fn:function(row){return [(row.prop.key === 'label')?_c('div',{staticClass:"text-left text-capitalize"},[_vm._v(" "+_vm._s(row.prop.item.label.toString().replace(/_/g, " "))+" ")]):_vm._e(),(row.prop.key === 'status')?_c('div',{staticClass:"text-center"},[_c('v-chip',{staticClass:"text-capitalize",attrs:{"color":row.prop.item.status == 'Approve'
                            ? 'success'
                            : 'danger',"label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(row.prop.item.status == "Approve" ? "mdi-check" : "mdi-close")+" ")]),_vm._v(" "+_vm._s(row.prop.item.status)+" ")],1)],1):_vm._e()]}}],null,true)})],1)],1)}),1)]:_vm._e()],2)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }