<template>
  <o-container ref="container">
    <v-card v-if="!showInformation" elevation="2" shaped>
      <v-card-title class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto ">
          {{ title }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="warning"
            icon="mdi-filter"
            type="label-icon"
            label="Filter"
            @on-click="showFilter = true"            
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-sync"
            type="label-icon"
            label="Refresh"
            @on-click="reset()"
          />

          <o-drawer
            v-if="!busy"
            :auto-close="true"
            :actions="[
              {
                key: 'on-item-reset',
                label: 'RESET',
                icon: 'mdi-refresh',
                color: 'warning',
              },
              {
                key: 'on-item-search',
                label: 'SEARCH',
                icon: 'mdi-text-box-search-outline',
                color: 'success',
              },
            ]"
            button-icon="mdi-filter-plus-outline"
            :title="`FILTERS`"
            :use-button="false"
            width="50vw"
            :visible="showFilter"
            @on-item-search="
              showFilter = false;
              reset();
            "
            @on-item-reset="
              showFilter = false;
              reset(true);
            "
            @on-close="showFilter = false"
          >
            <v-row class="pa-0 ma-0">
              <v-col cols="12">
                <o-combo-box
                  v-model="parameters.account_id"
                  :busy="busy"
                  label="Account"
                  :items="accounts"
                />
              </v-col>
              <v-col cols="12">
                <o-input
                  v-model="parameters.creative_asset_id"
                  :busy="busy"
                  label="Creative Asset ID"
                />
              </v-col>
              <v-col cols="12">
                <o-combo-box
                  v-model="parameters.source_name"
                  :busy="busy"
                  label="Source"
                  :items="sources"
                />
              </v-col>
              <v-col cols="12">
                <o-combo-box
                  v-model="parameters.failed"
                  :busy="busy"
                  label="Status"
                  :items="fails"
                />
              </v-col>
            </v-row>
          </o-drawer>
        </div>
      </v-card-title>
      <v-divider />

      <v-skeleton-loader
        v-if="busy"
        :loading="busy"
        type="table"
        height=""
        transition="v-scroll-x-transition"
      />

      <div :style="!busy && operation === '' ? '' : 'display:none'">
        <o-table
          ref="table"
          :busy="busy"
          type="rest"
          :has-paging="true"
          method="POST"
          :height="getHeight(440)"
          :fixed-header="true"
          :rest="{
            url: `/rest/intranet/execute?time=${new Date().getTime()}`,
            payload: {
              topic: '',
              name: 'check_asset_status',
              params: {
                a01: parameters.account_id,
                a02: parameters.failed,
                a03: parameters.creative_asset_id,
                a04: parameters.source_name,
                cnt: parameters.cnt,
                starts: parameters.starts,
              },
            },
          }"
          :headers="[
            { text: '#', align: 'end', value: 'row_no', type: 'int' },
            {
              text: 'Type',
              align: 'start',
              value: 'source_name',
              type: 'text',
            },
            {
              text: 'Account',
              align: 'start',
              value: 'account_name',
              type: 'slot',
            },
            {
              text: 'Advertiser',
              align: 'start',
              value: 'advertiser_name',
              type: 'slot',
            },
            {
              text: 'Asset ID',
              align: 'end',
              value: 'creative_asset_id',
              type: 'text',
              width: 100,
            },
            {
              text: 'Name',
              align: 'start',
              sortable: false,
              value: 'creative_asset_name',
              type: 'text',
            },
            {
              text: 'Meta Status',
              align: 'center',
              value: 'status',
              type: 'slot',
            },
            {
              text: 'Active',
              align: 'center',
              value: 'active',
              type: 'boolean',
            },
            {
              text: 'Transcoding Status',
              align: 'center',
              value: 'asset_status_name',
              type: 'slot',
            },
            {
              text: 'Encoded',
              align: 'end',
              value: 'encoded_assets',
              type: 'slot',
            },
            {
              text: 'Date Created',
              align: 'start',
              value: 'created_at',
              type: 'date-time',
            },
            {
              text: 'Created By',
              align: 'start',
              value: 'created_by_name',
              type: 'slot',
            },
            {
              text: 'Creatives',
              align: 'start',
              value: 'creative_count',
              type: 'slot',
            },
            {
              value: 'action',
              type: 'action',
              actions: [
                {
                  key: 'on-item-view',
                  label: 'View',
                  icon: 'mdi-information-outline',
                  color: 'success',
                },
              ],
            },
          ]"
          @set-busy="setBusy"
          @on-item-view="onItemView"
        >
          <template v-slot:slot="row">
            <div
              v-if="row.prop.key === 'creative_count'"
              class="text-center"
            >
              <o-button
                v-if="row.prop.item.creative_count > 0"
                color="blue lighten-2"
                icon="mdi-motion-play-outline"
                type="right-label-icon"
                :label="`${row.prop.item.creative_count}`"
                @on-click="onItemCreative(row.prop.item)"
              /> 
            </div>
            <div
              v-if="row.prop.key === 'account_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              {{ row.prop.item.account_name }} ({{ row.prop.item.account_id }})
            </div>
            <div
              v-if="row.prop.key === 'advertiser_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              {{ row.prop.item.advertiser_name }} ({{
                row.prop.item.advertiser_id
              }})
            </div>
            <div
              v-if="row.prop.key === 'created_by_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              {{ row.prop.item.created_by_name }} ({{
                row.prop.item.created_by
              }})
            </div>
            <div v-if="row.prop.key === 'encoded_assets'" class="text-right">
              {{ row.prop.item.encoded_assets.length }}
            </div>
            <div
              v-if="row.prop.key === 'asset_status_name'"
              class="text-center"
            >
              <v-chip
                class="text-capitalize"
                :color="
                  row.prop.item.asset_status_name == 'Encoded'
                    ? 'success'
                    : [
                        'Queue',
                        'Transcoding HIDEF',
                        'HIDEF transcoded',
                        'Transcoding Last Formats',
                      ].includes(row.prop.item.asset_status_name)
                    ? 'orange darken-1'
                    : 'danger'
                "
                label
                small
                text-color="white"
              >
                <v-icon small left>
                  {{
                    row.prop.item.asset_status_name == "Encoded"
                      ? "mdi-check"
                      : [
                          "Queue",
                          "Transcoding HIDEF",
                          "HIDEF transcoded",
                          "Transcoding Last Formats",
                        ].includes(row.prop.item.asset_status_name)
                      ? "mdi-wrench"
                      : "mdi-close"
                  }}
                </v-icon>
                {{ row.prop.item.asset_status_name  == 'Unretriable'?
                  'Not Transcodable' : row.prop.item.asset_status_name
                }} ({{
                  row.prop.item.asset_status 
                }})
              </v-chip>
            </div>

            <div v-if="row.prop.key === 'status'" class="text-center">
              <v-chip
                class="text-capitalize"
                :color="
                  row.prop.item.metadata.Status == 'Approved'
                    ? 'success'
                    : 'danger'
                "
                label
                small
                text-color="white"
              >
                <v-icon small left>
                  {{
                    row.prop.item.metadata.Status == "Approved"
                      ? "mdi-check"
                      : "mdi-close"
                  }}
                </v-icon>
                {{ row.prop.item.metadata.Status }}
              </v-chip>
            </div>
          </template>
        </o-table>
      </div>
    </v-card>

    <v-card v-if="showInformation" elevation="2" shaped>
      <v-card-title>
        <v-row class="ma-0 pa-0">
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="12"
            lg="6"
            xl="6"
            class="ma-0 pa-0"
          >
            <div class="text-uppercase pl-4 mr-auto">
              ASSET INFORMATION VIDEO
            </div>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="12"
            lg="6"
            xl="6"
            class="text-end ma-0 pa-0"
          >
            <o-button
              v-if="
                selectedAsset.video_status != 2 && selectedAsset.video_status != 11
              "
              :busy="busy"
              color="danger"
              icon="mdi-alert-circle-outline"
              label="Not Transcodable "
              type="label-icon"
              @on-click="unretry()"
            />

            <o-button
              :busy="busy"
              color="success"
              icon="mdi-sync"
              type="label-icon"
              label="Retry"
              @on-click="retry()"
            />

            <o-button
              :busy="busy"
              color="primary"
              icon="mdi-keyboard-backspace"
              type="label-icon"
              label="Back"
              @on-click="
                showInformation = false;
                showFilter = false;
              "
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider />

      <div class="ml-10 pb-5 mr-5">
        <v-row class="o-container-content">
          <v-col cols="12">
            <h5>SOURCE</h5>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
            <div class="d-flex justify-space-between">
              <p>Thumbnail:</p>
              <img
                v-if="selectedAsset.creative_thumbnail_name !== ''"
                height="100"
                :src="
                  selectedAsset.source_name == 'AUDIO'
                    ? selectedAsset.creative_thumbnail_name
                    : `${selectedAsset.media_host}/octilliontv/${selectedAsset.account_id}/${selectedAsset.advertiser_id}/thumb/${selectedAsset.creative_thumbnail_name}`
                "
              />
            </div>
            <div class="d-flex justify-space-between mt-6">
              <p>Account Name:</p>
              <span
                >{{ selectedAsset.account_name }} ({{
                  selectedAsset.account_id
                }})</span
              >
            </div>
            <div class="d-flex justify-space-between">
              <p>Type:</p>
              <span>{{ selectedAsset.source_name }}</span>
            </div>
            <div class="d-flex justify-space-between">
              <p>Asset ID:</p>
              <span>{{ selectedAsset.creative_asset_id }}</span>
            </div>
            <div class="d-flex justify-space-between">
              <p>Asset Name:</p>
              <span>{{ selectedAsset.creative_asset_name }}</span>
            </div>
            <div class="d-flex justify-space-between">
              <p>MIME Type:</p>
              <span>{{ selectedAsset.mime_type }}</span>
            </div>
            <div class="d-flex justify-space-between">
              <p>Encoding Profile:</p>
              <span>{{ selectedAsset.encoding_profile }}</span>
            </div>
            <div class="d-flex justify-space-between">
              <p>Date Created:</p>
              <span>{{ getDateTime(selectedAsset.created_at) }}</span>
            </div>
            <div class="d-flex justify-space-between">
              <p>Created By::</p>
              <span v-if="selectedAsset.created_by > 0">
                {{ selectedAsset.created_by_name }} ({{ selectedAsset.created_by }})
              </span>
            </div>
            <div class="d-flex justify-space-between">
              <p>Active:</p>
              <v-chip
                class=""
                :color="selectedAsset.active ? 'success' : 'danger'"
                label
                small
                text-color="white"
              >
                <v-icon small left>
                  {{ selectedAsset.active ? "mdi-check" : "mdi-close" }}
                </v-icon>
                {{ selectedAsset.active ? "Yes" : "No" }}
              </v-chip>
            </div>
            <div
              v-if="
                selectedAsset.asset_status && selectedAsset.asset_status_name
              "
              class="d-flex justify-space-between"
            >
              <p>Transcoding Status:</p>
              <v-chip
                class="text-capitalize"
                :color="
                  selectedAsset.asset_status_name == 'Encoded'
                    ? 'success'
                    : 'danger'
                "
                label
                small
                text-color="white"
              >
                <v-icon small left>
                  {{
                    selectedAsset.asset_status_name == "Encoded"
                      ? "mdi-check"
                      : "mdi-close"
                  }}
                </v-icon>
                {{ selectedAsset.asset_status_name }} ({{
                  selectedAsset.asset_status
                }})
              </v-chip>
            </div>
            <div
              v-if="selectedAsset.metadata && selectedAsset.metadata.Status"
              class="d-flex justify-space-between"
            >
              <p>Meta Status:</p>
              <v-chip
                class="text-capitalize"
                :color="
                  selectedAsset.metadata.Status == 'Approved'
                    ? 'success'
                    : 'danger'
                "
                label
                small
                text-color="white"
              >
                <v-icon small left>
                  {{
                    selectedAsset.metadata.Status == "Approved"
                      ? "mdi-check"
                      : "mdi-close"
                  }}
                </v-icon>
                {{ selectedAsset.metadata.Status }}
              </v-chip>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
            <o-table
              :busy="true"
              type="array"
              :has-paging="false"
              :no-count="true"
              :headers="[
                {
                  text: 'Meta Data',
                  align: 'start',
                  sortable: false,
                  value: 'label',
                  type: 'slot',
                },
                {
                  text: 'Status',
                  align: 'start',
                  sortable: false,
                  value: 'status',
                  type: 'slot',
                },
                {
                  text: 'Allowed',
                  align: 'start',
                  sortable: false,
                  value: 'allowed',
                  type: 'text',
                },
                {
                  text: 'Current',
                  align: 'end',
                  value: 'current',
                  type: 'text',
                },
              ]"
              :data="getMetaData(selectedAsset)"
            >
              <template v-slot:slot="row">
                <div
                  v-if="row.prop.key === 'label'"
                  class="text-left text-capitalize"
                >
                  {{ row.prop.item.label.toString().replace(/_/g, " ") }}
                </div>
                <div v-if="row.prop.key === 'status'" class="text-center">
                  <v-chip
                    class="text-capitalize"
                    :color="
                      row.prop.item.status == 'Approve' ? 'success' : 'danger'
                    "
                    label
                    small
                    text-color="white"
                  >
                    <v-icon small left>
                      {{
                        row.prop.item.status == "Approve"
                          ? "mdi-check"
                          : "mdi-close"
                      }}
                    </v-icon>
                    {{ row.prop.item.status }}
                  </v-chip>
                </div>
              </template>
            </o-table>
          </v-col>
          <template
            v-if="
              selectedAsset.preview_token && selectedAsset.preview_token != ''
            "
          >
            <v-col cols="12">
              <h5>PREVIEW</h5>
            </v-col>
            <v-col cols="12">
              <iframe
                style="max-height: 670px; height: 670px;"
                border="0"
                width="100%"
                :src="
                  `${getAPIUrl()}/creative_preview?preview=video&token=${
                    selectedAsset.preview_token
                  }`
                "
              />
            </v-col>
          </template>
          <template
            v-if="
              selectedAsset.encoded_assets &&
                selectedAsset.encoded_assets.length > 0
            "
          >
            <v-col cols="12">
              <h5>TRANSCODED</h5>
            </v-col>
            <v-col cols="12">
              <v-row
                v-for="(asset, i) in selectedAsset.encoded_assets"
                :key="i"
              >
                <v-col cols="12" class="mt-5 mb-0 pt-0 mb-0">
                  <h5>{{ asset.creative_asset_name }}</h5>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  class="mt-0 mb-0 pt-0 mb-0"
                >
                  <div class="d-flex justify-space-between">
                    <p>Asset ID:</p>
                    <span>{{ asset.creative_asset_id }}</span>
                  </div>
                  <div class="d-flex justify-space-between">
                    <p>MIME Type:</p>
                    <span>{{ asset.mime_type }}</span>
                  </div>
                  <div class="d-flex justify-space-between">
                    <p>Date Created:</p>
                    <span>{{ getDateTime(asset.created_at) }}</span>
                  </div>
                  <div class="d-flex justify-space-between">
                    <p>Active:</p>
                    <v-chip
                      class=""
                      :color="asset.active ? 'success' : 'danger'"
                      label
                      small
                      text-color="white"
                    >
                      <v-icon small left>
                        {{ asset.active ? "mdi-check" : "mdi-close" }}
                      </v-icon>
                      {{ asset.active ? "Yes" : "No" }}
                    </v-chip>
                  </div>
                  <div
                    v-if="asset.asset_status && asset.asset_status_name"
                    class="d-flex justify-space-between"
                  >
                    <p>Transcoding Status:</p>
                    <v-chip
                      class="text-capitalize"
                      :color="
                        asset.asset_status_name == 'Encoded'
                          ? 'success'
                          : 'danger'
                      "
                      label
                      small
                      text-color="white"
                    >
                      <v-icon small left>
                        {{
                          asset.asset_status_name == "Encoded"
                            ? "mdi-check"
                            : "mdi-close"
                        }}
                      </v-icon>
                      {{ asset.asset_status_name }} ({{ asset.asset_status }})
                    </v-chip>
                  </div>
                  <div
                    v-if="asset.metadata && asset.metadata.EncodedStatus"
                    class="d-flex justify-space-between"
                  >
                    <p>Meta Status:</p>
                    <v-chip
                      class="text-capitalize"
                      :color="
                        asset.metadata.EncodedStatus == 'Approved'
                          ? 'success'
                          : 'danger'
                      "
                      label
                      small
                      text-color="white"
                    >
                      <v-icon small left>
                        {{
                          asset.metadata.EncodedStatus == "Approved"
                            ? "mdi-check"
                            : "mdi-close"
                        }}
                      </v-icon>
                      {{ asset.metadata.EncodedStatus }}
                    </v-chip>
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <o-table
                    :busy="true"
                    type="array"
                    :has-paging="false"
                    :no-count="true"
                    :headers="[
                      {
                        text: 'Meta Data',
                        align: 'start',
                        sortable: false,
                        value: 'label',
                        type: 'slot',
                      },
                      {
                        text: 'Status',
                        align: 'start',
                        sortable: false,
                        value: 'status',
                        type: 'slot',
                      },
                      {
                        text: 'Allowed',
                        align: 'start',
                        sortable: false,
                        value: 'allowed',
                        type: 'text',
                      },
                      {
                        text: 'Current',
                        align: 'end',
                        value: 'current',
                        type: 'text',
                      },
                    ]"
                    :data="getMetaData(asset)"
                  >
                    <template v-slot:slot="row">
                      <div
                        v-if="row.prop.key === 'label'"
                        class="text-left text-capitalize"
                      >
                        {{ row.prop.item.label.toString().replace(/_/g, " ") }}
                      </div>
                      <div v-if="row.prop.key === 'status'" class="text-center">
                        <v-chip
                          class="text-capitalize"
                          :color="
                            row.prop.item.status == 'Approve'
                              ? 'success'
                              : 'danger'
                          "
                          label
                          small
                          text-color="white"
                        >
                          <v-icon small left>
                            {{
                              row.prop.item.status == "Approve"
                                ? "mdi-check"
                                : "mdi-close"
                            }}
                          </v-icon>
                          {{ row.prop.item.status }}
                        </v-chip>
                      </div>
                    </template>
                  </o-table>
                </v-col>
              </v-row>
            </v-col>
          </template>
        </v-row>
      </div>
    </v-card>
  </o-container>
</template>

<script>
import RestApi from "@/services/RestApi";
import { mapGetters } from "vuex";

export default {
  name: "AssetChecker",
  mixins: [RestApi],
  props: {
    dialogName: {
      type: String,
      default: "",
    },
  },
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Assets",
  },
  data() {
    return {
      title: "Assets",
      showFilter: false,
      operation: "",
      parameters: {
        account_id: 0,
        failed: 0,
        creative_asset_id: 0,
        source_name: "",
        cnt: 20,
        starts: 0,
      },
      accounts: [],
      sources: [
        { id: "", name: "N/A" },
        { id: "AUDIO", name: "AUDIO" },
        { id: "VIDEO", name: "VIDEO" },
      ],
      fails: [
        { id: 0, name: "ALL" },
        { id: 1, name: "FAILED" },
      ],

      busy: false,
      showInformation: false,
      selectedAsset: {},
      data: {},
      valid: false,
      code1: "<xml><head><title>Original aTitle</title></head></xml>",
      code2: '{"title": "Assets", "busy": false }',
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
    actionInformation() {
      if (!this.selectedAsset) return [];
      if (this.selectedAsset.asset_status == 11) return [];
      return [
        {
          key: "on-item-unretry",
          label: "Not Transcodable",
          icon: "mdi-close",
          color: "danger",
        },
        {
          key: "on-item-retry",
          label: "Retry",
          icon: "mdi-refresh",
          color: "danger",
        },
      ];
    },
  },
  async mounted() {
    if (
      this.$route.query.source_name &&
      ["AUDIO", "VIDEO"].includes(this.$route.query.source_name)
    ) {
      this.parameters.source_name = this.$route.query.source_name;
    }

    if (
      this.$route.query.creative_asset_id &&
      this.$route.query.creative_asset_id > 0
    ) {
      this.parameters.creative_asset_id = this.$route.query.creative_asset_id;
    }

    if (this.$route.query.account_id && this.$route.query.account_id > 0) {
      this.parameters.account_id = this.$route.query.account_id;
    }

    if (this.$route.query.show && this.$route.query.show > 0) {
      this.showInformation = true;
      setTimeout(async () => {
        this.busy = true;
        await this.postData(
          `/rest/intranet/execute?time=${new Date().getTime()}&inner=1`,
          {
            topic: "",
            name: "check_asset_status",
            params: this.parameters,
          }
        )
          .then((response) => {
            if (response.data.count > 0) {
              const item = response.data.results[0];
              this.onItemView(item);
            }
            this.busy = false;
          })
          .catch((err) => {
            this.busy = false;
          });
      }, 1000);
    }

    this.getData(`rest/accounts`, null).then((response) => {
      this.accounts.push({ id: 0, name: "All" });
      response.data.forEach((d) => {
        this.accounts.push({ id: d.id, name: d.account_name });
      });
      //this.advertisers.push({id: 0, name: 'All'})
    });
  },
  methods: {
    async reset(par) {
      if (par) {
        this.parameters = {
          account_id: 0,
          failed: 0,
          creative_asset_id: 0,
          source_name: "",
          cnt: 20,
          starts: 0,
        };
      }

      this.busy = true;
      
      setTimeout(async ()=> {  
          this.$refs.table.refresh();    
        }, 200);
            
    },
    getMetaData(item) {
      if (
        item &&
        item.metadata &&
        (item.metadata.EncodedMessage || item.metadata.Message)
      ) {
        const arr = [];
        const message = item.metadata.EncodedMessage
          ? item.metadata.EncodedMessage
          : item.metadata.Message;
        Object.keys(message).forEach((d) => {
          arr.push({
            label: d,
            status: message[d]["status"],
            allowed:
              d === "audio_depth"
                ? message[d][`allowed_bit_rate`]
                : message[d][`allowed_${d}`],
            current:
              d === "audio_depth"
                ? message[d][`current_bit_rate`]
                : message[d][`current_${d}`],
          });
        });
        return arr;
      } else {
        return [];
      }
    },
    compare(sd, ed) {
      const start = new Date(sd);
      const end = new Date(ed);
      const now = new Date(this.data.current_date.value);
      if (!(now >= start && now <= end)) return "text-red";
      return "";
    },
    onItemView(item) {
      this.selectedAsset = item;
      this.showInformation = true;
    },
    onItemCreative(item) {
      if (item.creative_id > 0) {
        this.$router.push(
          `/app/checker/creatives?primary_asset=${item.creative_asset_id}&account_id=${item.account_id}`
        );
      } else {
        this.$refs.container.snackBar("No Creative ID was specified.", "red", 2000);
      }
    },
    async retry() {
      const asset = this.selectedAsset;
      await this.$refs.container
        .confirm(
          "Confirmation",
          `You're about to reset transcoding for <strong>${this.selectedAsset.creative_asset_name} (${this.selectedAsset.creative_asset_id})</strong>. Do you want to continue?`,
          "warning"
        )
        .then(async (ans) => {
          if (ans) {
            await this.postData("/rest/intranet/execute", {
              topic: "",
              name: "retry_transcoding",
              params: {
                a01: asset.account_id,
                a02: asset.creative_asset_id,
                a03: asset.id,
                a04: asset.source_name,
              },
            })
              .then((response) => {
                if (
                  response.data &&
                  response.data.results &&
                  response.data.results.length > 0
                ) {
                  //this.$refs.table.refresh();
                  this.$refs.container.snackBar(
                    "Successfully move to queue for transcoding.",
                    "warning",
                    2000
                  );
                }
                this.showInformation = false;
              })
              .catch((err) => {
                setTimeout(() => {
                  this.busy = false;
                }, 200);
              });
          } else {
            setTimeout(() => {
              this.showInformation = false;
            }, 200);
          }
        });
    },
    async unretry() {
      const asset = this.selectedAsset;
      await this.$refs.container
        .confirm(
          "Confirmation",
          `You're about to set this to not transcodable asset <strong>${this.selectedAsset.creative_asset_name} (${this.selectedAsset.creative_asset_id})</strong>. Do you want to continue?`,
          "red"
        )
        .then(async (ans) => {
          if (ans) {
            await this.postData("/rest/intranet/execute", {
              topic: "",
              name: "unretry_transcoding",
              params: {
                a01: asset.account_id,
                a02: asset.creative_asset_id,
                a03: asset.id,
                a04: asset.source_name,
              },
            })
              .then((response) => {
                if (
                  response.data &&
                  response.data.results &&
                  response.data.results.length > 0
                ) {
                  this.$refs.container.snackBar(
                    "Successfully mark video as not transcodable.",
                    "red",
                    2000
                  );
                  //this.$refs.table.refresh();                  
                }
                this.showInformation = false;
              })
              .catch((err) => {
                setTimeout(() => {
                  this.busy = false;
                }, 200);
              });
          } else {
            setTimeout(() => {
              this.showInformation = false;
            }, 200);
          }
        });
    },  
  },
};
</script>
<style lang="scss" scoped>
.o-container-content {
  min-height: calc(100vh - 278px);
  max-height: calc(100vh - 278px);
  overflow-y: scroll;
}

.json-container {
  max-height: calc(100vh - 425px);
  overflow: scroll;
}
</style>
