<template>
  <AssetChecker dialog-name="Assets" />
</template>
<script>
import AssetChecker from '@/views/app/checker/Asset'

export default {
  name: 'AssetWrapper',
  components: {
    AssetChecker
  }
}
</script>
